import React from "react";

export let AppContext = React.createContext();
export let AppContextProvider = ({ children }) => {
  let [auth, setAuth] = React.useState(false);
  let [token, setToken] = React.useState("");

  let Login = (token) => {
    setAuth(true);
    setToken(token);
  };
  let Logout = () => {
    setAuth(false);
    setToken("");
  };
  return (
    <AppContext.Provider value={{ auth, token, Login, Logout }}>
      {children}
    </AppContext.Provider>
  );
};
