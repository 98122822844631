import React from "react"
function Pagination({page,handlepagechange}){
 
  return(
    <div>
      <button disabled={page===1} onClick={()=>handlepagechange(page-1)}>Prev</button>
      <button>{page}</button>
      <button disabled={page===26} onClick={()=>handlepagechange(page+1)}>Next</button>
    </div>
  )
}
export default Pagination