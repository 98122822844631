import "./styles.css";
import Navbar from "./Component/Navbar";
import AllRoute from "./Route/AllRoute";
export default function App() {
  return (
    <div className="App">
      <Navbar />
      <AllRoute />
    </div>
  );
}
