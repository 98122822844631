import React, { Children } from "react";
import { AppContext } from "../Context/AppContextProvider";
import { Navigate } from "react-router-dom";
function PrivateRoute({ children }) {
  let { auth } = React.useContext(AppContext);
  if (!auth) {
    return <Navigate to="/login" />;
  }
  return children;
}
export default PrivateRoute;
