import React from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
function SingleRestaurant() {
  let [data, setData] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  let { id } = useParams();
  React.useEffect(() => {
    axios.get(`https://dbioz2ek0e.execute-api.ap-south-1.amazonaws.com/mockapi/getrestaurants/${id}`).then((res) => {
      console.log(res.data.data);
      setData(res.data);
      setLoading(false);
    });
  });
  if (loading) {
    return (
      <div>
        <h1>Loading.....</h1>
      </div>
    );
  }
  return (
    <div>
      <h1>Single Product</h1>
      <div>
        <img style={{borderRadius:"20px"}} src={data.data.image} alt="xx" />
      </div>
      <div>
        <h2>{data.data.name}</h2>
        <h3>Type:{data.data.type}</h3>
        <p>Price:{data.data.price_starts_from}</p>
        <p>Votes:{data.data.number_of_votes}</p>
       
       
        <Link to="/product">Go Back</Link>
      </div>
    </div>
  );
}
export default SingleRestaurant;
