import React from "react";
import style from "../Style/ProductCard.module.css";
import { useNavigate } from "react-router-dom";
function RestaurantCard({ id, name,image, price_starts_from,number_of_votes }) {
  let navigate = useNavigate();
  return (
    <div className={style.product} onClick={() => navigate(`/product/${id}`)}>
      <img className={style.image} src={image} alt={name} />
      <h3>{name}</h3>
      <p>Price_start_from:{price_starts_from}</p>
     
  
      <p>Votes:{number_of_votes}</p>
     
    </div>
  );
}
export default RestaurantCard;
