import React from "react";
import { Link } from "react-router-dom";
function Navbar() {
  return (
    <div style={{display:"flex"}}>
      <div>
        <h1>Navbar</h1>
      </div>
      <div style={{display:"flex",alignItems:"center",textDecoration:"none",gap:"20px"}}>
      <div style={{ marginLeft: "30px" }}>
        <Link to="/">Home</Link>
      </div>
      <div >
       
        <Link to="/login">LoginPage</Link>
        <Link to="/product">Product Page</Link>
      </div>
      </div>
    </div>
  );
}
export default Navbar;
