import React from "react";
import axios from "axios";
import { AppContext } from "../Context/AppContextProvider";
import { useNavigate } from "react-router-dom";
export default function Login() {
  let [email, setEmail] = React.useState("");
  let [password, setPassword] = React.useState("");
  let { auth, token, Login, Logout } = React.useContext(AppContext);
  let navigate = useNavigate();
  let login = () => {
    if (!email || !password) {
      alert("Fill the blanks");
      return;
    }

    const payload = {
      email,
      password
    };
    axios.post("https://reqres.in/api/login", payload).then((res) => {
      alert("login Successfully");
      Login(res.data.token);
      navigate("/product");
    });
  };
  let logout = () => {
    Logout();
  };
  if (auth) {
    return (
      <div>
        <h1>token:{token}</h1>
        <button onClick={logout}>Logout</button>
      </div>
    );
  }
  return (
    <div className="App">
      <h1>Login Page</h1>
      <label>Email:</label>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <br />
      <label>Password:</label>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <br />
      <br />
      <button onClick={login}>Submit</button>
    </div>
  );
}
