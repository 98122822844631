import { Routes, Route } from "react-router-dom";
import Home from "./Home";

import Login from "../Component/Login";
import Restaurant from "./Restaurant";
import PrivateRoute from "../Component/PrivateRoute";
import SingleRestaurant from "./SignleRestaurant";
function AllRoute() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>

      <Route path="/login" element={<Login />}></Route>
      <Route
        path="/product"
        element={
          <PrivateRoute>
            <Restaurant />
          </PrivateRoute>
        }
      ></Route>
      <Route path="/product/:id" element={<SingleRestaurant />}></Route>
    </Routes>
  );
}
export default AllRoute;
