import React from "react";
import axios from "axios";
import RestaurantCard from "../Component/RestaurantCard";
import style from "../Style/Product.module.css";
import Pagination from "../Component/Pagination";
import { useSearchParams } from "react-router-dom";
function Restaurant() {
  let [data, setData] = React.useState(null);
  let [loading, setLoading] = React.useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  let [page, setPage] = React.useState(Number(searchParams.get("page")) || 1);
  let [limit, setLimit] = React.useState(
    Number(searchParams.get("limit")) ||4
  );

  React.useEffect(() => {
    getdata();
    setSearchParams({ limit: limit, page:page });
  }, [page]);
  let getdata = () => {
    axios
      .get(`https://dbioz2ek0e.execute-api.ap-south-1.amazonaws.com/mockapi/getrestaurants?page=${page}&limit=${limit}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data.data);
        setLoading(false);
      });
  };
  let handlepagechange = (value) => {
    setPage(value);
  };
  if (loading) {
    return (
      <div>
        <h1>Loading.....</h1>
      </div>
    );
  }
  return (
    <div>
      <h1>Product Page</h1>
      <div className={style.product}>
        {data.map((el) => (
          <RestaurantCard key={el.id} {...el} />
        ))}
      </div>
      <Pagination
        limit={limit}
        page={page}
        handlepagechange={handlepagechange}
      />
    </div>
  );
}
export default Restaurant;
